import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import element from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/icon.css'
import * as ElIconModules from '@element-plus/icons-vue'
import locale from 'element-plus/lib/locale/lang/zh-cn';//中文
import { VueAxios } from './utils/request'
import VuewechatTitle from 'vue-wechat-title' 
const app = createApp(App)
// 统一注册Icon图标
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName]
    app.component(iconName, item)
  }
}
app.use(store).use(router).use(VueAxios).use(element,{ locale }).use(VuewechatTitle).mount('#app')
