import {
    SIDEBAR_TYPE,
    DEFAULT_THEME,
    DEFAULT_LAYOUT_MODE,
    DEFAULT_COLOR,
    DEFAULT_COLOR_WEAK,
    DEFAULT_FIXED_HEADER,
    DEFAULT_FIXED_SIDEMENU,
    DEFAULT_FIXED_HEADER_HIDDEN,
    DEFAULT_CONTENT_WIDTH_TYPE,
    DEFAULT_MULTI_TAB
} from '@/store/mutation-types'

const app = {
    state: {
        tagsList: [],
        collapse: false,
        token: '',
        imgLoadStatus: false
    },
    mutations: {
        delTagsItem(state, data) {
            state
                .tagsList
                .splice(data.index, 1);
        },
        setTagsItem(state, data) {
            state
                .tagsList
                .push(data)
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data
                            .$router
                            .push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data
                            .$router
                            .push(state.tagsList[i - 1].path);
                    } else {
                        data
                            .$router
                            .push("/");
                    }
                    state
                        .tagsList
                        .splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        },
        CLOSE_SIDEBAR: (state) => {
            Vue.ls.set(SIDEBAR_TYPE, true)
            state.sidebar = false
        },
        imgLoadStatusMu(state, data) {
            state.imgLoadStatus = data;
        }

    },
    actions: {

    }
}

export default app
