import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: 'AUAUC'
    },
    component: () => import("../views/home/home.vue")
  },

  {
    path: "/playvideo",
    name: "playvideo",
    meta: {
      title: '小姐姐'
    },
    component: () => import("../views/playvideo/index.vue")
  },

  {
    path: "/404",
    name: "404",
    meta: {
      title: '404'
    },
    component: () => import("../views/abnormal/404.vue")
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: '403'
    },
    component: () => import("../views/abnormal/403.vue")
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/abnormal/404.vue'),
    //    404 page
  }

];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
