import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
 

// default router permission control 
// import permission from './modules/permission'   //静态路由时放开

// dynamic router permission control (Experimental)  
import getters from './getters'


export default new Vuex.Store({
  modules: {
    app
  },
  state: {
   
  },
  mutations: {

  },
  actions: {
    
  },
  getters
})
